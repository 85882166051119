import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { client } from '@expertli/lib/axios';

import { SetUserPrivacyConsentBody } from '../types';

type SetUserPrivacyOptions = {
  workspaceId?: string;
};

async function setUserPrivacy(
  body: SetUserPrivacyConsentBody | null,
  options: SetUserPrivacyOptions
) {
  const response = await client.post(`/api/user/privacy`, {
    ...body,
    ...options,
  });
  return response.data as SetUserPrivacyConsentBody & { slug?: string };
}

export function useSetUserPrivacy(options: SetUserPrivacyOptions = {}) {
  const queryClient = useQueryClient();
  const { data, mutateAsync, error, isError, isLoading, isSuccess } = useMutation(
    (body: SetUserPrivacyConsentBody | null) => setUserPrivacy(body, options),
    {
      onSuccess: () => queryClient.invalidateQueries(['user/privacy']),
    }
  );

  return {
    isLoading,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess,
    setUserPrivacy: mutateAsync,
    response: data,
  };
}
