import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { ErrorResponse } from 'expertli-lib/dist/errors';

import { client } from '@expertli/lib/axios';

import { SignUpBody } from '../types';

async function signUp(body: SignUpBody) {
  const response = await client.post(`/api/auth/sign-up`, body);
  return response.status;
}

export function useSignUp() {
  const { data, mutateAsync, error, isError, isLoading } = useMutation((body: SignUpBody) =>
    signUp(body)
  );
  return {
    isLoading,
    error: error as AxiosError<ErrorResponse>,
    isError,
    isSuccess: data === 204,
    signUp: mutateAsync,
  };
}
