import { useMutation } from 'react-query';

import { client } from '@expertli/lib/axios';

import { ResetMobileBody } from '../types';

export async function resetMobile({ email, mobileFourCharSuffix }: ResetMobileBody) {
  const response = await client.post(`/api/auth/reset-mobile`, {
    email,
    mobileFourCharSuffix,
  });
  return response;
}

export function useResetMobile(onError?: () => void) {
  const { data, mutateAsync, error, isError, isLoading } = useMutation(
    (body: ResetMobileBody) => resetMobile(body),
    {
      onError,
    }
  );
  return {
    isLoading,
    error,
    isError,
    isSuccess: data?.status === 204,
    requestOtp: mutateAsync,
  };
}
