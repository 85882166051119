import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  colors,
  styled,
} from '@mui/material';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { FormEvent, useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import OtpInput from 'react-otp-input';

import { gtmEventToDataAttributes } from '@expertli/lib/analytics';

import { resetMobile } from '../hooks/use-reset-mobile';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: 0,

  '& .verification-code-input': {
    '> div': {
      marginLeft: 12,
    },
    input: {
      marginRight: 12,
      '&:focus': {
        border: `solid 2px ${theme.palette.common.black}`,
        '&::placeholder': {
          color: 'transparent',
        },
      },
      width: '56px !important',
      height: '56px',
      borderRadius: 8,
      fontSize: 24,
      border: `solid 1px ${theme.palette.grey[700]}`,
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '48px !important',
        height: '48px',
        borderRadius: 6,
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        width: '46px !important',
        height: '46px',
        borderRadius: 6,
        fontSize: 16,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
  },
}));

const Header = styled('h1')(({ theme }) => ({
  fontSize: '2rem',
  textAlign: 'left',
  marginBottom: 12,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.9rem',
  },
}));

const ResendLink = styled(Link)({
  color: 'inherit',
  fontWeight: 'bold',
  cursor: 'pointer',
});

type Props = {
  email: string;
  mobile: string;
  onResend: () => void;
  callbackUrl?: string;
  sendWelcomeEmail?: boolean;
  logoUrl?: string;
};

export function Verify(props: Props) {
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onSubmit = useCallback(
    async (event?: FormEvent) => {
      event?.preventDefault();
      setIsLoading(true);
      const response = await signIn('one-time-code-verifier', {
        code,
        email: props.email,
        redirect: false,
        sendWelcomeEmail: props.sendWelcomeEmail,
        callbackUrl: props.callbackUrl,
      });

      if (response.error) {
        setIsLoading(false);
        setCode('');
        if (response.error === 'expired') {
          router.push(
            `/auth/error?callbackUrl=${
              props.callbackUrl || (router.query.callbackUrl as string) || encodeURIComponent('/')
            }`
          );
        } else {
          setError(
            response.error === 'ratelimited'
              ? 'Too many attempts, please try again later.'
              : 'Invalid code, please try again.'
          );
        }
      } else {
        router.push(props.callbackUrl || (router.query.callbackUrl as string) || '/');
      }
    },
    [code, props.callbackUrl, props.email, props.sendWelcomeEmail, router]
  );

  useEffect(() => {
    if (code.length === 6 && !isLoading) {
      buttonRef.current?.focus();
      onSubmit();
    }
  }, [code, onSubmit, isLoading]);

  const isValid = code.length === 6;
  return (
    <Root>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={6}
        lg={4}
        xl={3}
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
      >
        {props.logoUrl && (
          <Grid item xs={12} pl={2}>
            <img
              style={{
                maxWidth: '285px',
                maxHeight: '64px',
                objectFit: 'scale-down',
                objectPosition: 'left center',
              }}
              src={props.logoUrl}
              alt="logo"
              draggable={false}
              width="304px"
              height="64px"
              crossOrigin="anonymous"
            />
          </Grid>
        )}
        <Box padding={2}>
          <Header>Please verify your identity</Header>
          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
            We&apos;ve sent a 6-digit code to your
            {props.mobile ? ` mobile ${props.mobile}` : ` ${props.email} email`}. Please check your
            {props.mobile ? ' mobile ' : ' email '}and enter the code below to securely access your
            room.
          </Typography>
        </Box>
        <Box
          padding={2}
          pt={0}
          component="form"
          mb={2}
          mt={1}
          onSubmit={onSubmit}
          sx={{ width: '100%' }}
          autoComplete="off"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className="verification-code-input"
            mb={4}
          >
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputType="text"
              shouldAutoFocus
            />
            {error && (
              <Alert
                severity="error"
                sx={{ marginTop: 2 }}
                action={<CloseOutlined onClick={() => setError(null)} />}
              >
                {error}
              </Alert>
            )}
          </Box>
          <LoadingButton
            ref={buttonRef}
            loading={isLoading}
            disabled={!isValid}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            loadingIndicator={<CircularProgress size={16} sx={{ color: colors.common.black }} />}
            sx={{ mb: 1 }}
            {...gtmEventToDataAttributes('log-in:otp-verified')}
          >
            Verify
          </LoadingButton>
          <Typography variant="caption">
            Don&apos;t have a code?&nbsp;
            <ResendLink
              onClick={() => {
                setError(null);
                props.onResend();
              }}
            >
              Resend code
            </ResendLink>
          </Typography>
          {(props.mobile || '').length > 4 && (
            <>
              <br />
              <Typography variant="caption">
                Mobile number incorrect?&nbsp;
                <ResendLink
                  onClick={async () => {
                    await resetMobile({
                      email: props.email,
                      mobileFourCharSuffix: props.mobile.substring(props.mobile.length - 4),
                    });
                    setError(null);
                    props.onResend();
                  }}
                >
                  Reset mobile
                </ResendLink>
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Root>
  );
}
