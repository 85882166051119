import { Button, Stack } from '@mui/material';
import { signIn } from 'next-auth/react';

import { AtlassianIcon, GoogleIcon, MicrosoftIcon } from '@expertli/components/social-icons';
import config from '@expertli/config';

type Props = {
  callbackUrl?: string;
};

export function SocialProviders({ callbackUrl }: Props) {
  const signInOptions = {
    redirect: true,
    ...(!!callbackUrl ? { callbackUrl } : {}),
  };

  return (
    <Stack spacing={2}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => signIn('atlassian', signInOptions)}
        startIcon={<AtlassianIcon />}
        size="large"
      >
        Continue with Atlassian
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => signIn('google', signInOptions)}
        startIcon={<GoogleIcon />}
        size="large"
      >
        Continue with Google
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => signIn('azure-ad', signInOptions)}
        startIcon={<MicrosoftIcon />}
        size="large"
      >
        Continue with Microsoft
      </Button>
    </Stack>
  );
}
