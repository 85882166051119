import { Button, GlobalStyles, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { Logo } from './logo';

const HeaderStyle = styled('header')(({ theme }) => ({
  lineHeight: 0,
  width: '100%',
  padding: theme.spacing(1, 2, 0),
  [theme.breakpoints.up('sm')]: {
    top: 0,
    left: 0,
    position: 'absolute',
    padding: theme.spacing(1, 5, 0),
  },
}));

type Props<T> = {
  logo?: React.ReactNode;
  pageLink?: {
    text: string;
    href: string;
  };
} & PropsWithChildren<T>;

export default function SimpleLayout<T>({ children, logo, pageLink }: Props<T>) {
  return (
    <>
      <GlobalStyles
        styles={{
          'html, body, #__next': {
            height: '100%',
            minHeight: '100%',
          },
        }}
      />
      <HeaderStyle>
        <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          {logo || <Logo />}
          {pageLink && (
            <Link href={pageLink.href} passHref>
              <Button
                sx={{
                  fontWeight: 500,
                  display: 'flex',
                  whiteSpace: 'nowrap',
                }}
                variant="outlined"
              >
                {pageLink.text}
              </Button>
            </Link>
          )}
        </Grid>
      </HeaderStyle>
      {children}
    </>
  );
}
