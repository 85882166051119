import defaultJoi from 'joi';
import JoiPhoneNumber from 'joi-phone-number';

import privacy from '@expertli/pages/api/user/privacy';

import { SetUserPrivacyConsentBody, SignUpBody } from './types';

const Joi = defaultJoi.extend(JoiPhoneNumber) as typeof defaultJoi;

export const signUpSchema = Joi.object<SignUpBody>({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .message('Please enter a valid email address')
    .lowercase()
    .trim(),
  firstName: Joi.string()
    .required()
    .messages({
      'any.required': 'Please enter your first name',
    })
    .trim(),
  lastName: Joi.string()
    .required()
    .messages({
      'any.required': 'Please enter your last name',
    })
    .trim(),
  privacyAccepted: Joi.boolean()
    .required()
    .custom((value, helpers) => {
      if (!value) {
        return helpers.error('any.required');
      }
      return value;
    })
    .message('You must accept the privacy policy.'),
}).options({ convert: true });

export const setUserPrivacySchema = Joi.object<SetUserPrivacyConsentBody>({
  firstName: Joi.string().required(),
  lastName: Joi.string().optional().allow(null, ''),
  mobile: Joi.string()
    .optional()
    .phoneNumber({
      defaultCountry: ['AU'],
      format: 'e164',
      strict: true,
    })
    .allow(null, ''),
  privacyAsked: Joi.boolean().optional(),
  privacyAccepted: Joi.boolean()
    .optional()
    .when('privacyAsked', {
      is: true,
      then: Joi.boolean()
        .custom((value, helpers) => {
          if (!value) {
            return helpers.error('any.required');
          }
          return value;
        })
        .message('You must accept the privacy policy.'),
    }),
  workspaceId: Joi.string().optional().allow(null, ''),
}).options({ allowUnknown: false, convert: true });
