import { useQuery } from 'react-query';

import config from '@expertli/config';
import { client } from '@expertli/lib/axios';

import { GetUserPrivacyConsentResponse } from '../types';

const { NEXT_PUBLIC_USER_PRIVACY_VERSION } = config;

export const getUserPrivacy = async (): Promise<GetUserPrivacyConsentResponse> => {
  const response = await client.get<GetUserPrivacyConsentResponse>('/api/user/privacy');
  return response.data;
};

export function useGetUserPrivacy() {
  const { data, isLoading } = useQuery(['user/privacy'], () => getUserPrivacy(), {
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    userPrivacy: data,
    hasAcceptedLatestVersion: data?.version === NEXT_PUBLIC_USER_PRIVACY_VERSION,
  };
}
