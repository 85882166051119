export function GoogleIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.18182 12.7128C6.18182 12.0641 6.28955 11.4423 6.48182 10.859L3.11637 8.28905C2.46046 9.62079 2.09091 11.1214 2.09091 12.7128C2.09091 14.3028 2.46 15.8025 3.115 17.1333L6.47864 14.5583C6.28818 13.9778 6.18182 13.3582 6.18182 12.7128Z"
        fill="#FBBC05"
      />
      <path
        d="M12.0909 6.81205C13.5 6.81205 14.7727 7.31134 15.7727 8.12836L18.6818 5.2234C16.9091 3.68014 14.6364 2.72694 12.0909 2.72694C8.13909 2.72694 4.74273 4.98692 3.11636 8.28904L6.48182 10.859C7.25727 8.5051 9.46773 6.81205 12.0909 6.81205Z"
        fill="#EB4335"
      />
      <path
        d="M12.0909 18.6135C9.46773 18.6135 7.25727 16.9204 6.48182 14.5665L3.11636 17.136C4.74273 20.4386 8.13909 22.6986 12.0909 22.6986C14.53 22.6986 16.8586 21.8325 18.6064 20.2098L15.4118 17.7402C14.5105 18.308 13.3755 18.6135 12.0909 18.6135Z"
        fill="#34A853"
      />
      <path
        d="M21.6364 12.7128C21.6364 12.1227 21.5455 11.4872 21.4091 10.8972H12.0909V14.7553H17.4545C17.1864 16.0707 16.4564 17.082 15.4118 17.7402L18.6064 20.2098C20.4423 18.5059 21.6364 15.9677 21.6364 12.7128Z"
        fill="#4285F4"
      />
    </svg>
  );
}

export default GoogleIcon;
