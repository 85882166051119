/* eslint-disable @next/next/no-html-link-for-pages */
import { SxProps } from '@mui/material';
import { styled } from '@mui/system';

import { InvertIfDarkMode } from '@expertli/features/theme/components/invert-if-dark-mode';
import { useWorkspaceContext } from '@expertli/features/workspace/context';

type OrgLogoProps = {
  logoUrl?: string;
  fallBackLogoUrl: string;
  isSmall?: boolean;
  sx?: SxProps;
};

const StyledImg = styled('img')({
  objectFit: 'scale-down',
  objectPosition: 'left center',
});

export const OrgLogo = (props: OrgLogoProps) => {
  return (
    <StyledImg
      {...props}
      sx={{
        maxWidth: props.isSmall ? '52px' : '285px',
        maxHeight: props.isSmall ? '52px' : '64px',
        ...props.sx,
      }}
      src={props.logoUrl ?? props.fallBackLogoUrl}
      alt="logo"
      draggable={false}
      width={props.isSmall ? '64px' : '304px'}
      crossOrigin="anonymous"
    />
  );
};

export const Logo = () => {
  const orgSettings = useWorkspaceContext()?.workspace?.organisation;
  return (
    <InvertIfDarkMode disabled={!!orgSettings?.logoUrl}>
      <OrgLogo logoUrl={orgSettings?.logoUrl} fallBackLogoUrl="/images/expertli-logo.svg" />
    </InvertIfDarkMode>
  );
};

export const Icon = () => {
  const orgSettings = useWorkspaceContext()?.workspace?.organisation;
  return (
    <InvertIfDarkMode disabled={!!orgSettings?.smallLogoUrl}>
      <OrgLogo
        logoUrl={orgSettings?.smallLogoUrl}
        isSmall
        fallBackLogoUrl="/images/expertli-icon.svg"
      />
    </InvertIfDarkMode>
  );
};
