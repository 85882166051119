import { useTheme } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export const InvertIfDarkMode: FC<{ disabled?: boolean }> = ({ children, disabled }) => {
  const theme = useTheme();
  if (disabled) {
    return <>{children}</>;
  }

  if (theme.mode === 'dark') {
    return (
      <>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child as ReactElement, {
            className: `${(child as ReactElement).props.className} invert-image`,
          });
        })}
      </>
    );
  }
  return <>{children}</>;
};
