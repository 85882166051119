export function MicrosoftIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 11.5L3 11.5L3 3.5L11 3.5L11 11.5Z" fill="#FF5722" />
      <path d="M21 11.5L13 11.5V3.5L21 3.5V11.5Z" fill="#4CAF50" />
      <path d="M21 21.5H13V13.5L21 13.5V21.5Z" fill="#FFC107" />
      <path d="M11 21.5H3L3 13.5L11 13.5L11 21.5Z" fill="#03A9F4" />
    </svg>
  );
}

export default MicrosoftIcon;
