import { joiResolver } from '@hookform/resolvers/joi';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  colors,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import config from '@expertli/config';

import { useRequestOtp, useSignUp } from '../hooks';
import { SignUpBody } from '../types';
import { signUpSchema } from '../validators';
import { PrivacyConsentContent } from './privacy-consent-content';
import { SocialProviders } from './social-providers';
import { Verify } from './verify';

const { NEXT_PUBLIC_EXPERTLI_URL } = config;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: 0,
  minHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
  },
}));

type Props = {
  mode: 'sign-up' | 'request-access';
  callbackUrl?: string;
  email?: string;
};

export function SignUp(props: Props) {
  const { status } = useSession();
  const router = useRouter();
  const { isSuccess, signUp, isLoading, error } = useSignUp();
  const { requestOtp } = useRequestOtp();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
  } = useForm<SignUpBody>({
    mode: 'onChange',
    resolver: joiResolver(signUpSchema),
    defaultValues: {
      email: props.email,
    },
  });

  const onSubmit = async (data: SignUpBody) => {
    await signUp(data);
  };

  useEffect(() => {
    if (status === 'authenticated') {
      if (router.query.callbackUrl) {
        router.push(router.query.callbackUrl as string);
      } else if (props.callbackUrl) {
        router.push(props.callbackUrl);
      } else {
        router.push(NEXT_PUBLIC_EXPERTLI_URL);
      }

      return;
    }
  }, [props.callbackUrl, router, status]);

  if (isSuccess) {
    let callbackUrl = '/get-started';

    if (props.callbackUrl) {
      callbackUrl = props.callbackUrl;
    } else if (router.query.callbackUrl) {
      callbackUrl = router.query.callbackUrl as string;
    } else if (router.query.solution) {
      callbackUrl = `/get-started?solution=${router.query.solution as string}`;
    }

    return (
      <Verify
        email={getValues().email}
        callbackUrl={callbackUrl}
        mobile=""
        onResend={async () => {
          await requestOtp({
            email: getValues().email,
          });
        }}
        sendWelcomeEmail={true}
      />
    );
  }

  return (
    <Root>
      <Grid
        item
        container
        xs={12}
        sm={9}
        md={6}
        lg={5}
        xl={3}
        justifyContent="stretch"
        alignItems="stretch"
        direction={xs ? 'column' : 'row'}
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        padding={2}
      >
        <Grid item xs={12}>
          {props.mode === 'sign-up' && (
            <Typography variant="h3" component="h1" sx={{ textAlign: 'left' }}>
              Get started for free
            </Typography>
          )}
          {props.mode === 'request-access' && (
            <Typography variant="h4" component="h1" sx={{ textAlign: 'left' }}>
              Want to enter this room? Sign up or log in
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <SocialProviders />
          <Divider sx={{ marginTop: '24px', marginBottom: '16px' }}>OR</Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
            Enter your email address and we’ll send you a one-time passcode to securely access your
            room.
          </Typography>
        </Grid>
        <Grid item xs={xs ? 12 : 6} sx={{ width: '100%' }}>
          <TextField
            {...register('firstName')}
            name="firstName"
            type="text"
            placeholder="First name"
            variant="outlined"
            fullWidth
            error={Boolean(isSubmitted && errors.firstName)}
            helperText={isSubmitted && errors.firstName && 'Please enter your first name'}
          />
        </Grid>
        <Grid item xs={xs ? 12 : 6} sx={{ width: '100%' }}>
          <TextField
            {...register('lastName')}
            name="lastName"
            type="text"
            placeholder="Last name"
            variant="outlined"
            fullWidth
            error={Boolean(isSubmitted && errors.lastName)}
            helperText={isSubmitted && errors.lastName && 'Please enter your last name'}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <TextField
            {...register('email')}
            name="email"
            type="email"
            placeholder="Work email"
            variant="outlined"
            fullWidth
            error={Boolean(isSubmitted && errors.email)}
            helperText={isSubmitted && errors.email && 'Please enter your valid email address'}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Checkbox
              {...register('privacyAccepted')}
              name="privacyAccepted"
              inputProps={{
                role: 'checkbox',
              }}
            />
            <PrivacyConsentContent />
          </Stack>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">
              {error.response.data.code !== 'email_exists' &&
                (error.response?.data?.message || 'An error occurred, please try again.')}
              {error.response.data.code === 'email_exists' && (
                <>
                  A user already exists with this email, please{' '}
                  <Link href="/auth/log-in">log in</Link> instead.
                </>
              )}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sx={{ width: '100%' }}>
          <LoadingButton
            loading={isLoading}
            disabled={!isValid}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            loadingIndicator={<CircularProgress size={16} sx={{ color: colors.common.black }} />}
          >
            Get started
          </LoadingButton>
        </Grid>
      </Grid>
    </Root>
  );
}
