import { joiResolver } from '@hookform/resolvers/joi';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  colors,
  styled,
} from '@mui/material';
import Joi from 'joi';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { OrgLogo } from '@expertli/layouts/logo';
import { analytics_event, trackLogInEvent } from '@expertli/lib/analytics';

import { useRequestOtp } from '../hooks';
import { RequestOtpBody } from '../types';
import { SignUp } from './sign-up';
import { SocialProviders } from './social-providers';
import { Verify } from './verify';

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .lowercase()
    .message('Please enter a valid email address'),
}).options({ convert: true });

type Props<T> = {
  buttonText?: string;
  callbackUrl?: string;
  trackingAction?: analytics_event['action'];
  heading?: string;
  logoUrl?: string;
  organisationId?: string;
  disableSocialLogIn?: boolean;
  email?: string | null;
  showRequestAccessOnError?: boolean;
  createUserOnDemand?: boolean;
  manualSubmit: boolean;
} & PropsWithChildren<T>;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
  },
}));

const LoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export function LogIn<T>(props: Props<T>) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const callbackUrl = (props.callbackUrl || '/') as string;
  const { requestOtp, isLoading, isSuccess, isError } = useRequestOtp();
  const [showVerify, setShowVerify] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const nextAuthErrorCode = router.query.error as string | undefined;
  const submitEmailRef = useRef<HTMLButtonElement>();
  const [mobile, setMobile] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: {
      email: props.email,
    },
  });

  const onSubmit = async (data: RequestOtpBody) => {
    const result = await requestOtp({
      email: data.email,
      organisationId: props.organisationId,
      createUserOnDemand: props.createUserOnDemand || false,
    });
    setMobile(result?.methodType === 'mobile' ? result?.method : '');
    setEmail(data.email);
    trackLogInEvent({
      action: props.trackingAction || 'request-otp',
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setShowVerify(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (props.email && submitEmailRef.current && !showVerify && !props.manualSubmit) {
      setTimeout(() => {
        submitEmailRef.current.click();
      }, 0);
    }
  }, [props.email, props.manualSubmit, showVerify]);

  if (isError && props.showRequestAccessOnError) {
    return <SignUp mode="request-access" callbackUrl={router.asPath} email={getValues().email} />;
  }

  if (showVerify && email) {
    return (
      <Verify
        logoUrl={props.logoUrl}
        email={email}
        mobile={mobile}
        callbackUrl={callbackUrl}
        onResend={async () => {
          await onSubmit({ email, organisationId: props.organisationId });
          enqueueSnackbar("We've resent the code to your registered contact detail", {
            variant: 'success',
          });
        }}
      />
    );
  }

  return (
    <Root>
      <div
        style={{
          backgroundColor: 'yellow',
          padding: '8px 32px',
          border: 'solid 1px black',
        }}
      >
        <p>
          Expertli will be closing February 28th 2024. All data will be permanently deleted on this
          date.
        </p>
        <p>
          Please export your data to PDF prior to this date. Contact{' '}
          <a href="mailto:hello@expertli.com">hello@expertli.com</a> if you have any questions
        </p>
      </div>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={6}
        lg={4}
        xl={3}
        justifyContent="justify"
        alignItems="justify"
        direction="column"
      >
        {nextAuthErrorCode && nextAuthErrorCode === 'OAuthAccountNotLinked' && (
          <Alert severity="error">
            You have not linked your account with any social provider. Please use the email to
            login.
          </Alert>
        )}
        {props.logoUrl && (
          <Grid item xs={12} pl={2}>
            <OrgLogo logoUrl={props.logoUrl} fallBackLogoUrl={props.logoUrl} />
          </Grid>
        )}
        <Box padding={2}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            {props.heading || 'Log in to Expertli'}
          </Typography>
        </Box>
        {!props.disableSocialLogIn && (
          <Grid item xs={12} p={2}>
            <SocialProviders callbackUrl={callbackUrl} />
            <Divider sx={{ marginTop: '24px', marginBottom: '16px' }}>OR</Divider>
          </Grid>
        )}
        {props.children}
        <Box
          padding={2}
          pt={0}
          component="form"
          mb={5}
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: '100%' }}
        >
          <TextField
            {...register('email')}
            name="email"
            type="email"
            placeholder="Email address"
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
            error={Boolean(isSubmitted && errors.email)}
            helperText={isSubmitted && errors.email?.message}
          />
          {isError && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              An error occurred, please try again.
            </Alert>
          )}
          <LoadingButton
            loading={isLoading}
            disabled={!isValid}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            ref={submitEmailRef}
            loadingIndicator={<CircularProgress size={16} sx={{ color: colors.common.black }} />}
          >
            {props.buttonText || 'Send passcode'}
          </LoadingButton>
        </Box>
      </Grid>
    </Root>
  );
}
