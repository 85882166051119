import { useMutation } from 'react-query';

import { client } from '@expertli/lib/axios';

import { RequestOtpBody } from '../types';

async function requestOtp({ createUserOnDemand, email, organisationId }: RequestOtpBody) {
  const response = await client.post(`/api/auth/request-otp`, {
    email,
    organisationId,
    createUserOnDemand,
  });
  return response.data as { method: string; methodType: string };
}

export function useRequestOtp(onError?: () => void) {
  const { data, mutateAsync, error, isError, isLoading } = useMutation(
    (body: RequestOtpBody) => requestOtp(body),
    {
      onError,
    }
  );
  return {
    isLoading,
    error,
    isError,
    isSuccess: !!data,
    requestOtp: mutateAsync,
  };
}
