import { Link as MuiLink, Typography, styled } from '@mui/material';

import { EXPERTLI_PRIVACY_POLICY_URL, EXPERTLI_TERMS_URL } from 'expertli-lib/dist/constants';

const Link = styled(MuiLink)({
  color: 'inherit',
  fontWeight: 'bold',
  cursor: 'pointer',
});

export const PrivacyConsentContent = () => {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'left', fontSize: 12 }}>
      By using Expertli’s platform, I acknowledge and agree that my personal information will be
      collected and handled under Expertli’s&nbsp;
      <Link href={EXPERTLI_PRIVACY_POLICY_URL} target="_blank">
        Privacy Policy
      </Link>
      &nbsp;and &nbsp;
      <Link href={EXPERTLI_TERMS_URL} target="_blank">
        Terms of use
      </Link>
      , which I have read and understood.
    </Typography>
  );
};
